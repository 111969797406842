@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

[data-options-layout='withFlavours'] {
	--margin-push: 70px;
}

.wheel,
.contentContainer {
	position: absolute;
	width: 50%;
	height: 100%;
}

.wheel {
	left: 0;
	z-index: 2;
}

.contentContainer {
	right: 0;
	z-index: 1;
}

.content {
	max-width: calc(#{rescale(400px)} - var(--margin-push, 0px));
	min-height: 100%;
	margin-left: calc(12vw + var(--margin-push, 0px));

	display: flex;
	flex-direction: column;

	justify-content: center;

	[data-locale='de'] & {
		max-width: calc(#{rescale(430px)} - var(--margin-push, 0px));
	}

	[data-locale='uk'] & {
		max-width: calc(665px - var(--margin-push, 0px));
	}
}

//larger container for Vuse US
.contentLarge {
	max-width: 650px;
	margin-left: calc(8vw + var(--margin-push, 0px));

	[data-locale='uk'] &,
	[data-locale='za'] & {
		margin-left: calc(10.5vw + var(--margin-push, 0px));
	}
}

.singleRight {
	min-height: 100%;
	display: flex;

	[data-locale='uk'] &,
	[data-locale='za'] & {
		padding-bottom: 50px;
	}
}

.backLabel {
	position: absolute;
	top: 50%;
	left: 8vw;
	transform: translateY(-50%);
	z-index: 10;

	[data-display='circles'] & {
		left: 5.2vw;
		top: 49%;
	}

	button {
		width: rescale(125px);
		height: rescale(125px);

		background-color: #fff;

		border-radius: 50%;
		border: 4px solid black;
		box-shadow: 0px 0px 0px 4px white;

		text-transform: uppercase;
		text-align: center;
		line-height: 1;
		letter-spacing: -0.01em;

		font-weight: var(--font-weight-bold);
		font-size: rescale(17px);
		color: black;

		display: flex;
		align-items: center;
		justify-content: center;

		[data-locale='uk'] &,
		[data-locale='za'] & {
			width: 170px;
			height: 170px;
			padding: 0 10px;

			font-family: $font-family-gotham;
			font-size: 20px;
			letter-spacing: 0.02em;
			line-height: 1;
		}
	}
	[data-locale='us'] & {
		transform: translateX(62%) translateY(-80%);
	}
	[data-locale='uk'] &,
	[data-locale='za'] & {
		transform: translateX(55%) translateY(calc(-50% - 50px));
	}
}

.videos {
	width: 100%;

	padding: 0 5vw 0 22vw;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
	z-index: 5;

	[data-locale='us'] & {
		justify-content: space-around;
		padding-bottom: 112px;
	}
	[data-locale='us'][data-theme='velo'] & {
		padding-bottom: 125px;
		padding-left: 23vw;
	}
	[data-locale='uk'] &,
	[data-locale='za'] & {
		padding: 0 135px 0 26vw;
	}
}

:global(.trademark) {
	top: -2.3em;
	font-size: 0.25em;
}

:global(.trademarkSmallFont) {
	top: -1em;
	font-size: 0.45em;
}
