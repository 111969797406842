@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.container {
	position: relative;

	width: 100%;
}

.spinner {
	position: relative;
	border-radius: 50%;

	user-select: none;
	overflow: hidden;
	z-index: 2;

	width: 100%;
	height: 0;
	padding-top: 100%;
}

.inner {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.img {
	width: 100%;

	pointer-events: none;
	display: block;
}

.injected {
	position: absolute;

	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	overflow: hidden;
	pointer-events: none;

	border-radius: 50%;
	overflow: hidden;

	z-index: 5;

	display: grid;
	grid-template-columns: 1fr rescale(500px) 1fr;
	grid-template-rows: 1fr rescale(500px) 1fr;

	[data-theme='vuse-epod'] & {
		grid-template-columns: 1fr 700px 1fr;
		grid-template-rows: 1fr 700px 1fr;
	}

	[data-display='circles'] & {
		left: 85px;
	}
	[data-options-layout='withFlavours'] & {
		left: 60px;
	}
}

.injectedChild {
	grid-area: 2 / 2 / 3 / 3;
	display: flex;
	flex-direction: column;
	position: relative;

	[data-locale='us'] & {
		margin-top: 20px;
		grid-area: 2 / 1 / 3 / 4;
	}
}
