@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.logo {
	width: rescale(100px);
	display: block;

	[data-locale='za'][data-theme='glo'] & {
		width: 102px;
		margin-bottom: 60px;
	}
	[data-locale='za'][data-theme='velo'] & {
		width: 95px;
		margin-bottom: 60px;
	}
	[data-locale='us'][data-theme='velo'] & {
		width: 315px;
	}
	[data-locale='uk'] & {
		width: 159px;
		margin-bottom: 60px;
	}
}

.text {
	font-family: var(--theme-font-brand);
	font-weight: var(--font-weight-demi);
	letter-spacing: 0.01em;
	font-size: rescale(40px);
	line-height: 1.1;
	white-space: pre-wrap;

	margin-top: rescale(18px);

	[data-theme='glo'] & {
		font-family: $font-family-mont;
		font-weight: $mont-heavy;
		font-size: 60px;
		line-height: 1;
		letter-spacing: 0;

		white-space: pre-wrap;
	}

	[data-theme='vype'] &,
	[data-theme='lyft'] &,
	[data-theme='velo'] & {
		text-transform: uppercase;
		letter-spacing: 0.06em;
	}

	[data-locale='us'] & {
		font-size: 46px;
		text-transform: uppercase;
		font-family: $font-family-gotham;
		font-weight: $gotham-bold;
		margin-top: rescale(38px);
	}

	[data-locale='us'][data-theme='velo'] & {
		font-family: $font-family-velo;
		font-weight: $velo-bold;

		color: white;
		font-size: 72px;
		line-height: 72px;
		letter-spacing: 0;

		margin-top: 30px;
	}
	[data-locale='uk'][data-theme='velo'] & {
		font-size: 58px;
		font-weight: $velo-regular;
		line-height: 0.965;
	}

	[data-theme='velo'] [data-options-layout='withFlavours'] & {
		font-family: $font-family-velo;
		font-size: 64px;
		font-weight: $velo-bold;
		line-height: 1;
		letter-spacing: 0;
	}

	[data-locale='za'] & {
		margin-top: 40px;
	}

	[data-locale='za'][data-theme='velo'] & {
		font-size: 58px;
		font-weight: $velo-regular;
		line-height: 56px;
	}

	[data-locale='uk']:not([data-theme='velo']) &,
	[data-locale='za']:not([data-theme='velo']):not([data-theme='glo']) & {
		font-family: $font-family-trim;
		font-weight: $trim-black;
		font-size: 68px;
		line-height: 0.941;
		letter-spacing: 0;
		text-transform: uppercase;
	}
}

.instructions {
	[data-locale='us'] & {
		font-size: 46px;
		font-family: $gotham-book;
		font-weight: normal;
		margin-bottom: 26px;
		line-height: 50px;
		margin-top: 20px;
	}
}

.icons {
	margin-left: -30px;
}
