@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

[data-options-layout='withFlavours'] {
	--margin-left-for-dots: -7%;
}

[data-options-layout='withFlavours'][data-display='circles'] {
	--margin-left-for-dots: 0%;
}

.container {
	position: absolute;
	left: 30%;
	top: 50%;

	z-index: 5;

	[data-locale='us'] & {
		left: 35%;
	}

	[data-locale='uk'] &,
	[data-locale='za'] & {
		left: calc(35% + var(--margin-left-for-dots, 0%));
	}
}

.dotPosition {
	position: absolute;
}

.dot {
	position: relative;

	width: 4vh;
	height: 4vh;

	transform: translate(-50%, -50%);

	&::before,
	&::after {
		content: '';
		border-radius: 50%;
		background-color: var(--dotColor);

		position: absolute;
		width: 33%;
		height: 33%;

		top: 33%;
		left: 33%;

		transition: transform 0.2s ease-out;
		z-index: 2;

		[data-options-layout='withFlavours'] & {
			width: 15px;
			height: 15px;

			top: 15px;
			left: 15px;
		}
	}

	&::after {
		opacity: 0.6;
		z-index: 1;
	}

	&.selected {
		&::before {
			transform: scale(2);

			[data-options-layout='withFlavours'] & {
				transform: scale(1);
			}
		}

		&::after {
			transform: scale(3);

			[data-options-layout='withFlavours'] & {
				transform: scale(2);
			}
		}
	}
}

.flavourName {
	position: absolute;
	left: calc(100% + 5px);
	top: 50%;
	transform: translateY(-50%);

	min-width: 185px;
	height: fit-content;
	line-height: 1.2em;

	color: var(--dotColor, white);
	white-space: normal;
	font-size: 19px;
	font-weight: 700;
	font-family: 'Gotham', sans-serif;
	text-transform: capitalize;
}

[data-locale='us'][data-theme='velo'] .dot {
	&.selected {
		&::before {
			transform: scale(1);
		}

		&::after {
			transform: scale(1.8);
		}
	}
}
