@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.tile {
	width: 55%;

	& + & {
		margin-left: 40px;
	}
	[data-locale='uk'] &,
	[data-locale='za'] & {
		width: 620px;
	}
	[data-locale='us'][data-theme='vuse'] & {
		width: 45%;
	}
	[data-locale='us'][data-theme='velo'] & {
		width: 44%;
	}
}

.thumb {
	position: relative;
	height: 0;
	padding-top: 56.25%;

	border: solid 10px white;

	img {
		display: block;

		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;

		object-fit: cover;
	}
}

.title {
	background-color: rgba(#fff, 0.5);
	padding: 20px;

	text-align: center;
	text-transform: uppercase;

	span {
		font-weight: var(--font-weight-bold);
	}

	[data-locale='us'][data-theme='velo'] & {
		background-color: #a5a5a5;

		font-size: 22px;
		color: white;

		padding: 26px 24px;
	}

	[data-locale='uk'] &,
	[data-locale='za'] & {
		background-color: #3d3e3f;

		font-size: 22px;
		color: white;

		padding: 31px 25px 34px;
	}
}

button.imgButton {
	width: 60px;
	height: 60px;

	transform: translate(-50%, -50%);

	position: absolute;
	top: 50%;
	left: 50%;

	filter: drop-shadow(0px 5px 10px rgba(black, 0.7));

	img {
		object-fit: contain;
		left: 0;
	}
}
