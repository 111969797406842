@import '../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../node_modules/bat-components/dist/styles/includes/functions';

.pageWrapper {
	font-family: $font-family-gotham;
	text-align: center;

	background-color: black;
}

.appReset {
	position: fixed;
	top: 0px;
	right: 0px;
	width: 2vw;
	height: 2vw;
	z-index: 1000;
	background: transparent;
	border: none;
}

:root {
	touch-action: pan-x pan-y;
	height: 100%;
}
