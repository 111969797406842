@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.bar {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: rescale(82px);

	display: flex;
	align-items: stretch;

	z-index: 10;

	[data-locale='za'] & {
		align-items: center;
		background: white;
		height: 150px;
	}
}

.opaque {
	background-color: white;

	flex: 1 0;

	position: relative;

	[data-theme='lyft'] &,
	[data-theme='velo'] & {
		background-color: var(--flavour-color);
	}

	[data-locale='us'][data-theme='velo'] & {
		background-color: white;
	}
}

.opaqueInner {
	padding: 16px;

	display: flex;
	align-items: center;

	[data-locale='us'][data-theme='velo'] & {
		height: 100%;
	}
}

.transparent {
	position: relative;
	display: flex;
	align-items: center;

	padding: 0 14px;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;

		background-color: white;

		opacity: 0.7;
	}

	[data-theme='lyft'] &::after,
	[data-theme='velo'] &::after {
		background-color: var(--flavour-color);
	}
}

.heading {
	font-size: rescale(24px);
	text-transform: uppercase;

	flex: 1;

	text-align: center;

	font-weight: var(--font-weight-bold);
	color: black;

	span {
		color: var(--flavour-color);
	}

	[data-locale='za'] & {
		font-size: 36px;
		font-family: $font-family-gotham;
		font-weight: $gotham-book;

		span {
			color: black;
			font-weight: $gotham-bold;
		}
	}

	[data-theme='lyft'] &,
	[data-theme='velo'] & {
		color: rgba(white, 0.5);

		span {
			color: white;
		}
	}

	[data-theme='velo'][data-locale='us'] & {
		color: #393838;
		font-family: $font-family-velo;
		font-weight: $velo-bold;

		font-size: 44px;
		padding: 0;

		span {
			color: var(--flavour-color);
		}
	}
}

.rating {
	user-select: none;

	color: #b3b3b3;
	border-left: solid 1px rgba(black, 0.7);
	padding: 0 40px;

	[data-theme='lyft'] & {
		border-color: white;
		color: #a1badd;
	}

	[data-theme='velo'] & {
		border-color: white;
		color: white;
	}

	[data-locale='us'][data-theme='velo'] & {
		border-color: #b3b3b3;
		padding: 0 91px 42px 73px;
		padding-bottom: 0;
	}
	[data-locale='za'] & {
		padding-left: 90px;
		padding-right: 105px;
	}
}

.stars {
	font-size: rescale(9px);
	text-transform: uppercase;
}

.starList {
	margin: 0 -8px;
	touch-action: none;

	& > * {
		touch-action: none;
	}
}

.flavourTitle {
	display: flex;
	justify-content: center;
	align-items: center;

	span {
		margin: 0 10px;
	}

	&::before,
	&::after {
		content: '';
		height: 1px;
		background-color: rgba(#b3b3b3, 0.5);

		[data-theme='velo'] & {
			background-color: rgba(white, 0.5);
		}

		flex: 1;
	}

	[data-theme='velo'][data-locale='us'] & {
		color: #b3b3b3;
		&::before,
		&::after {
			background-color: rgba(#b3b3b3, 0.5);
		}
	}
}

.star {
	color: var(--flavour-color);
	font-size: rescale(38px);
	display: inline-block;
	padding: 4px 8px;
	line-height: 0.7;

	[data-theme='velo'] &,
	[data-theme='lyft'] & {
		color: white;
	}

	[data-theme='velo'][data-locale='us'] & {
		color: var(--flavour-color);
		font-size: 56px;
	}

	[data-locale='za'] & {
		font-size: 66px;
	}
}

.markers {
	display: flex;
	justify-content: space-between;

	[data-theme='velo'] & {
		color: rgba(white, 0.5);
	}

	[data-theme='velo'][data-locale='us'] & {
		color: #b3b3b3;
	}
}

.confirm {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
}

.checkmark {
	--check-color: var(--flavour-color);

	width: 56px;
	height: 56px;
	border-radius: 50%;
	display: inline-block;
	stroke-width: 2;
	stroke: white;
	stroke-miterlimit: 10;
	box-shadow: inset 0px 0px 0px var(--flavour-color);
	animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;

	[data-theme='lyft'] &,
	[data-theme='velo'] & {
		--check-color: white;
	}
}

.checkmark__circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: var(--check-color);
	fill: none;
	animation: stroke 0.6s ease-in-out forwards;
}

.checkmark__check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s ease-in-out 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes scale {
	0%,
	100% {
		transform: none;
	}
	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}

@keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 30px var(--flavour-color);
	}
}
