@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.container {
	font-size: 40px;
	text-transform: uppercase;
	font-family: $font-family-gotham;
	text-align: center;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;

	[data-theme='velo'] & {
		text-transform: unset;
		width: 500px;
		max-height: 345px;

		height: 100%;
	}

	[data-display='circles'] & {
		margin-left: 100px;
	}

	[data-locale='za'] & {
		padding: 0 0 0 80px;
	}
}

.startTrialInner {
	width: 100%;
	padding: 0 15px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	[data-locale='za'] & {
		padding: 0;
	}
}

.tryFlavor {
	display: flex;
	font-size: 24px;

	[data-theme='velo'] & {
		font-family: $font-family-velo;
		color: white;

		padding: 0px 104px;
		margin-bottom: 35px;
	}

	[data-theme='velo'] [data-display='circles'] & {
		font-family: $font-family-din;
		font-size: 24px;
		font-weight: 400;
		margin: 10px 0 35px 0;
		padding: 0;
	}

	[data-locale='uk'] &,
	[data-locale='za'] & {
		font-family: $font-family-gotham;
		font-weight: $gotham-book;
		line-height: 1.333;
		letter-spacing: 0;

		max-width: 400px;
		margin-bottom: 35px;
	}
}

.flavourIcon {
	width: 190px;
	padding: 32px;
}

.mainText {
	line-height: 22px;

	[data-theme='velo'] & {
		color: white;
		font-family: $font-family-velo;
		font-weight: $din-pro-medium;
		font-size: 50px;
		text-transform: unset;
		line-height: 46px;
	}
	[data-locale='uk'] &,
	[data-locale='za'] & {
		font-family: $font-family-gotham;
		font-weight: $gotham-book;
		font-size: 40px;
		line-height: 1.15;
	}

	[data-theme='velo'] [data-display='circles'] & {
		font-size: 36px;
		font-family: $font-family-velo;
		font-weight: $velo-regular;

		letter-spacing: 0.01em;
		line-height: 1.22;
	}
}

.mainTextTrialStarted {
	color: white;
	font-family: $font-family-velo;
	font-weight: $din-pro-medium;
	font-size: 35px;
	text-transform: unset;
	line-height: 46px;
}

.flavourName {
	color: var(--flavour-color);
	font-family: $font-family-gotham;
	font-weight: $gotham-black;

	margin-bottom: 24px;

	[data-theme='velo'] & {
		color: white;
		font-family: $font-family-velo;
		font-weight: $velo-bold;
		text-transform: uppercase;
		font-size: 56px;
	}

	[data-theme='velo'] [data-display='circles'] & {
		color: var(--flavour-color);
		text-transform: initial;

		font-family: $font-family-velo;
		font-weight: $velo-bold;
		font-size: 60px;

		letter-spacing: 0;
		margin-bottom: 25px;
	}

	[data-locale='uk'] &,
	[data-locale='za'] & {
		color: white;
		margin-bottom: 38px;
	}
}

[data-locale='uk'] .questionMark,
[data-locale='za'] .questionMark {
	font-family: $font-family-gotham;
	font-weight: $gotham-book;
}

.comingSoon {
	font-family: $font-family-gotham;
	font-weight: $gotham-black;
	font-size: 28px;
	text-transform: uppercase;

	[data-theme='velo'] & {
		color: white;
		margin-top: 20px;
	}
}

.noVideo {
	margin-left: 390px;
	margin-bottom: 120px;
}

.plusTax {
	font-size: 8px;
	margin: 7px 5px;
	line-height: 10px;

	text-transform: lowercase;
	display: inline-flex;
	flex-direction: column;

	text-align: start;
}

.startTrialButton {
	max-width: 65%;
	max-width: 260px;

	[data-locale='uk'] &,
	[data-locale='za'] & {
		max-width: unset;
	}
}
