@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wheel {
	position: absolute;

	user-select: none;
	z-index: 2;

	width: 120vh;
	height: 120vh;
	top: 50%;

	[data-screenType='tab-s4'] & {
		transform: translateX(-29%) translateY(-50%);
	}

	[data-screenType='qb13r'] & {
		transform: translateX(-27%) translateY(-50%);
	}

	[data-screenType='qb13r'][data-locale='uk'] &,
	[data-screenType='qb13r'][data-locale='za'] & {
		transform: translateX(-23%) translateY(-50%);
	}
}

.productImgBg {
	height: 100%;
	width: 100%;
	max-width: 635px;

	object-fit: contain;

	position: absolute;
	top: 0;
	left: -20px;
}
