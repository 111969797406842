@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.block {
	height: rescale(600px);

	font-family: $font-family-gotham;

	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	align-items: center;

	[data-theme='velo'][data-locale='us'] & {
		color: white;
	}
}

.blockInside {
	grid-area: 1 / 1;
	width: 100%;

	display: grid;
	gap: rescale(35px);
	grid-template-rows: repeat(3, auto);
	grid-template-columns: 1fr;
	grid-template-areas:
		'logo'
		'switching'
		'variants';

	&.hasSubContent {
		grid-template-rows: repeat(4, auto);

		grid-template-areas:
			'logo'
			'switching'
			'variants'
			'button';
	}

	[data-locale='us'] & {
		gap: 0;
	}
	[data-locale='uk'] &,
	[data-locale='za'] & {
		gap: 40px;
	}

	[data-locale='za'][data-theme='glo'] & {
		gap: 30px;
	}
}

// --- Grids --- //

.logoContainer {
	grid-area: logo;
}

.logo {
	width: rescale(100px);
	display: block;

	[data-locale='us'] & {
		margin-bottom: 25px;
		width: rescale(83px);
	}

	[data-locale='us'][data-theme='velo'] & {
		width: 160px;
	}
	[data-locale='za'][data-theme='velo'] & {
		display: none;
	}

	[data-locale='za'][data-theme='glo'] & {
		width: 102px;
	}
}

.slogan {
	font-size: 18px;
	text-transform: uppercase;

	[data-locale='us'] & {
		margin-bottom: 10px;
	}
}

.switching {
	grid-area: switching;

	display: grid;
	grid-template-rows: auto 1fr;
	grid-template-areas: 'name' 'info';

	[data-locale='us'][data-theme='vuse'] & {
		color: black;
		grid-template-rows: 84px 1fr;
	}
}

.variantsWrapper {
	grid-area: variants;

	[data-locale='us'][data-theme='vuse'] & {
		color: black;
	}
}

.variants {
	display: flex;
	align-items: flex-end;

	[data-locale='uk'] & {
		max-width: 520px;
	}
}

.button {
	grid-area: button;

	a,
	button {
		margin: 0;
		// margin-top: 25px;
	}

	[data-locale='us'] & {
		padding-top: 25px;
	}

	[data-locale='us'][data-theme='velo'] & {
		max-width: 250px;
	}
}

[data-locale='us'] .tryButton {
	font-size: 22px;
	font-weight: bold;
}

// --- Switching Area --- //
.type {
	text-transform: uppercase;
	font-size: rescale(14px);

	[data-locale='uk'] &,
	[data-locale='za'] & {
		font-size: 18px;
	}

	[data-locale='za'][data-theme='glo'] & {
		text-transform: none;
	}

	[data-theme='velo'] [data-display='circles'] & {
		font-family: $font-family-gotham;
		font-size: 18px;
		font-weight: $gotham-bold;
		letter-spacing: 0;
		text-transform: unset;
	}
}

.nameWrapper {
	position: relative;
	padding-right: rescale(70px);

	grid-area: name;

	[data-locale='pl'] &,
	[data-locale='us'] & {
		align-self: flex-end;
		padding-right: 50px;
	}
}

.name {
	font-size: rescale(40px);
	line-height: 1;
	letter-spacing: 0.06em;
	font-weight: $gotham-bold;
	color: var(--flavour-color, white);

	text-transform: uppercase;

	margin-top: rescale(4px);

	white-space: pre-wrap;

	[data-locale='us'] & {
		margin-top: 0;
		white-space: pre-line;
		font-size: 40px;
		font-weight: $gotham-black;
		line-height: 46px;
		letter-spacing: 0;
	}

	[data-locale='us'][data-theme='velo'] & {
		font-family: $font-family-velo;
		font-weight: $din-black-italic;
		color: white;
		font-size: 72px;
		line-height: 62px;
	}

	[data-locale='uk']:not([data-theme='velo']) & {
		font-family: $font-family-gotham;
		font-weight: $gotham-black;
		font-size: 44px;
		letter-spacing: 0;

		margin: 10px 0 32px;
	}

	[data-locale='uk'][data-theme='velo'] & {
		font-family: $font-family-velo;
		font-weight: $velo-medium;
		font-size: 64px;
		letter-spacing: 0;

		max-width: 490px;
		margin: 5px 0 20px;
	}

	[data-locale='za'] & {
		font-family: $font-family-gotham;
		font-weight: $gotham-black;
		font-size: 44px;
		letter-spacing: 0;

		margin: 10px 0 32px;
	}

	[data-locale='za'][data-theme='velo'] & {
		font-family: $font-family-velo;
		font-weight: $velo-medium;
		font-size: 64px;
		line-height: 44px;
		letter-spacing: 0;
	}
	[data-locale='za'][data-theme='glo'] & {
		font-family: $font-family-mont;
		font-weight: $mont-regular;
		font-size: 64px;
		line-height: 1;
		letter-spacing: 0;

		margin: 10px 0 12px;
	}

	[data-theme='velo'] [data-display='circles'] & {
		font-size: 64px;
		font-family: $font-family-velo;
		font-weight: $velo-bold;

		letter-spacing: 0;
		text-transform: initial;
	}
}

.productType {
	font-family: $font-family-velo;
	font-weight: $velo-regular;

	font-size: 16px;
	text-transform: uppercase;
	padding-bottom: 5px;

	[data-locale='us'][data-theme='velo'] & {
		padding-bottom: 0;
	}
}

.drip {
	flex: 0 0 60px;
	align-self: flex-start;
	margin-left: 20px;

	width: rescale(60px);

	position: absolute;
	bottom: 0;
	right: 0;

	grid-area: info;

	[data-locale='us'] & {
		width: rescale(81px);
		bottom: -15px;
	}
}

.info {
	font-size: rescale(18px);
	line-height: 1.2;
	font-weight: $gotham-book;
	letter-spacing: 0;

	margin-top: 10px;

	[data-locale='us'][data-theme='vuse'] & {
		font-family: $gotham-book;
		line-height: 30px;
		font-size: 24px;

		margin: 30px 0px;
		min-height: 90px;
	}

	[data-locale='us'][data-theme='velo'] & {
		font-family: $font-family-velo;
		font-weight: $velo-regular;

		line-height: 32px;
		font-size: 25px;

		margin-top: 30px;
		max-width: 443px;
		min-height: 128px;
	}

	[data-locale='uk'] & {
		font-size: 24px;
		min-height: 60px;
		margin: 0 0 20px 0;

		font-family: $font-family-gotham;
		line-height: 1.25em;

		max-width: 510px;
	}
	[data-locale='uk'][data-theme='velo'] & {
		min-height: 140px;
	}

	[data-locale='us'][data-theme='velo'] & {
		font-size: 23px;
		font-family: $font-family-gotham;
		line-height: 1.25em;

		max-width: 510px;
	}
}

.infoExtraLine {
	height: 30px;
}

// --- Variants Area --- //
.variantBlock {
	flex: 50%;
	padding-right: 30px;
	color: inherit;

	[data-locale='za'][data-theme='velo'] & {
		flex: 0 0 auto;
	}

	& + & {
		border-left: 1px solid white;
		padding-left: 30px;

		[data-locale='uk'] & {
			border-left: none;
			padding-left: 0px;
		}
	}
}

.variantTitle {
	grid-area: variantTitle;
	text-transform: uppercase;
	font-size: rescale(12px);

	[data-locale='us'] & {
		font-size: 24px;
		font-family: $gotham-book;
	}

	[data-locale='us'][data-theme='velo'] & {
		font-family: $font-family-velo;
		font-size: 16px;
	}

	[data-locale='uk'] & {
		font-size: 18px;
		font-weight: $gotham-book;
		letter-spacing: 0;
	}

	[data-locale='za'] & {
		font-size: 24px;
		font-weight: $gotham-book;
	}

	[data-locale='za'][data-theme='glo'] & {
		font-size: 18px;
		font-weight: $gotham-book;
		margin-bottom: 15px;
	}
}

.vgSubtitle {
	font-size: 14px;
	font-weight: $gotham-book;
	margin-bottom: 15px;
}

.variantPrice {
	font-size: rescale(40px);
	line-height: 0.9;
	font-weight: $gotham-bold;

	margin-top: 6px;

	[data-locale='us'] & {
		font-size: 40px;
		font-weight: unset;

		display: flex;
		flex-direction: row;
	}

	[data-theme='velo'] & {
		font-family: $font-family-velo;
	}

	[data-theme='velo'] [data-display='circles'] & {
		font-weight: $velo-medium;
	}
	[data-locale='us'][data-theme='velo'] & {
		font-family: $font-family-velo;
		font-weight: $din-black-italic;

		font-size: 48px;
	}

	[data-locale='uk'] & {
		font-size: 44px;
		line-height: 1;
		letter-spacing: 0;
	}

	[data-locale='uk'][data-theme='velo'] & {
		font-size: 64px;
		font-weight: $velo-regular;

		margin-bottom: 20px;
	}

	[data-locale='za'] & {
		font-size: 40px;
		line-height: 1;
		letter-spacing: 0;
		font-weight: $gotham-book;
	}

	[data-locale='za'][data-theme='glo'] & {
		font-size: 56px;
		font-weight: $gotham-bold;
	}
}

.priceDetail {
	font-family: $font-family-gotham;
	font-size: 20px;
	font-weight: $gotham-bold;
	letter-spacing: 0;
	margin-left: 5px;
}

.plusTax {
	font-size: 10px;
	margin: 5px;
	margin-left: 7px;
	line-height: 12px;

	display: flex;
	flex-direction: column;
}

.variantBtn {
	margin-top: rescale(20px) !important;

	[data-locale='us'][data-theme='vuse'] & {
		padding: rescale(5px) rescale(15px);
		font-weight: bold;
		margin-top: 20px;
	}
	[data-locale='us'][data-theme='velo'] & {
		margin-top: 46px;
	}

	[data-locale='za'] & {
		font-size: 18px;
		font-weight: $gotham-bold;
		letter-spacing: 0.04em;
		line-height: 1.11;
		padding: 15.5px 45px !important;
		color: black !important;
		background-color: white !important;
	}

	[data-locale='za'][data-theme='velo'] & {
		font-size: 18px;
		font-weight: $gotham-bold;
		letter-spacing: 0.04em;
		line-height: 1.11;
		padding: 15.5px 45px !important;
		margin: 40px 0 0 0 !important;
	}
}

.optionWrapper {
	display: flex;

	.optionPriceContainer {
		min-width: 180px;
	}
	.optionTitle {
		display: block;
		margin-bottom: 10px;

		font-family: $font-family-gotham;
		font-weight: $gotham-book;
		font-size: 18px;
		letter-spacing: 0;
		line-height: 26px;
	}
	.optionPrice {
		font-size: 64px;
		letter-spacing: 0;
		line-height: 40px;

		display: block;
		margin-bottom: 50px;

		[data-theme='velo'] & {
			font-family: $font-family-velo;
			font-weight: $velo-regular;
		}
	}
}

//I had to make this class because all the above button styles are wrong
.invertDE {
	background-color: black !important;
	color: white !important;
}

// --- Modal --- //
.modalTitle {
	text-transform: uppercase;
	align-items: center;
	font-size: rescale(29px);
	font-weight: $gotham-black;
	line-height: 1;

	color: var(--theme-color);

	[data-locale='uk'] &,
	[data-locale='za'] &,
	[data-locale='us'][data-theme='vuse'] & {
		font-size: 40px;
	}

	[data-locale='za'][data-theme='velo'] & {
		font-family: $font-family-velo;
		font-weight: $velo-regular;
		font-size: 40px;
	}
	[data-locale='us'][data-theme='velo'] & {
		font-family: $font-family-velo;
		font-weight: $din-black-italic;
		font-size: 56px;
	}
}

.modal {
	text-align: center;
	padding-bottom: 20px;
	[data-locale='us'][data-theme='vuse'] & {
		padding: 84px 56px;
		font-weight: $gotham-book;
	}

	[data-locale='us'][data-theme='velo'] & {
		font-family: $font-family-velo;
		font-size: 25px;
	}

	[data-locale='za'][data-theme='velo'] & {
		min-width: 640px;
	}
}

.modalInner {
	padding: 30px;
}

.modalVG {
	font-size: rescale(16px);
	font-weight: $gotham-bold;
	text-transform: uppercase;

	margin-top: 16px;

	[data-locale='us'] & {
		font-weight: $gotham-book;
	}
	[data-locale='uk'] & {
		font-size: 26px;
	}
}

.modalFeatures {
	font-size: rescale(13px);
	margin-top: 14px;

	[data-locale='us'][data-theme='vuse'] & {
		font-size: 24px;
		font-weight: $gotham-book;
	}

	[data-locale='uk'] & {
		font-size: 26px;
		font-weight: $gotham-book;
		margin-top: 30px;
		line-height: 1.23;
		letter-spacing: 0;
	}

	[data-locale='za'] & {
		font-size: 26px;
		font-weight: $gotham-book;
		margin-top: 40px;
		line-height: 1.23;
		letter-spacing: 0;
	}


	[data-locale='de'] & {
		font-size: 26px;
		font-weight: $gotham-book;
		margin-top: 40px;
		line-height: 1.23;
		letter-spacing: 0;
	}

	[data-locale='us'][data-theme='velo'] & {
		font-size: 25px;
		margin-top: 10px;
		font-weight: $velo-regular;
	}
}

.modalAdvice {
	font-size: rescale(12px);
	margin-top: 14px;
	// width: 58%;
	margin: auto;
	margin-top: 20px;

	[data-locale='us'][data-theme='vuse'] & {
		margin-left: 130px;
		margin-right: 130px;
		margin-top: 20px;
	}
	[data-locale='us'][data-theme='velo'] & {
		font-weight: $velo-regular;
		font-size: 25px;
	}
	[data-locale='uk'] & {
		margin-top: 50px;
		font-size: 20px;
		line-height: 1.3;
		font-weight: $gotham-book;
	}

	[data-locale='za'] & {
		margin-top: 60px;
		font-size: 20px;
		line-height: 1.3;
		font-weight: $gotham-book;
	}
}

.btnContainer button {
	margin-top: 70px;
	margin-left: 0;
}
