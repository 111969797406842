@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.productImgContainer {
	height: 80%;
	width: 100%;
	align-self: center;

	position: relative;

	[data-locale='us'] & {
		height: 100%;
		margin-left: 100px;
	}

	[data-locale='uk'] &,
	[data-locale='za'] & {
		height: 100%;
		flex: 0 0 auto;
	}
}

.productImg {
	height: 100%;
	width: 100%;
	object-fit: contain;
	position: absolute;
	top: 0;
}

.productName {
	padding-top: 20px;
	grid-area: info;
	text-align: center;
}

.productNameTitle {
	font-family: var(--theme-font-brand);
	font-size: rescale(42px);
	line-height: 1.2;

	[data-locale='uk'] & {
		font-size: 32px;
	}

	[data-theme='vype'] & {
		font-weight: var(--font-weight-bold);
	}

	[data-theme='lyft'] & {
		text-transform: uppercase;
		font-weight: var(--font-weight-bold);
	}
	[data-theme='glo'] & {
		font-weight: var(--font-weight-demi);
	}
	[data-theme='velo'] & {
		text-transform: uppercase;
		font-weight: var(--font-weight-light);
		white-space: pre-wrap;
	}
	[data-theme='vuse-epod'] & {
		font-size: 32px;
		letter-spacing: 0;
		font-weight: $gotham-bold;
	}
}

.productNameAfter {
	font-family: var(--theme-font-primary);
	font-size: rescale(17px);
}

.spinGraphic {
	position: absolute;
	right: 18vh;
	top: 50%;

	width: rescale(18px);
	transform: translateY(-50%);

	z-index: 10;

	[data-locale='us'][data-theme='vuse'] & {
		top: 39%;
		transform: rotate(-3deg);
		right: 18vh;
	}

	[data-display='circles'] & {
		right: 18vh;
	}
}
