@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

[data-display='circles'] {
	--text-color: white;
}

.variantLevels {
	display: flex;

	margin-top: 16px;

	[data-locale='us'] & {
		margin-top: 30px;
	}

	[data-locale='us'][data-theme='velo'] & {
		max-width: 128px;
	}
	[data-locale='za'] & {
		margin-top: 30px;
	}
}

.variantLevel {
	position: relative;

	font-size: rescale(12px);
	width: rescale(22px);
	height: rescale(22px);

	border-radius: 50%;

	background-color: var(--flavour-color);
	color: white;

	display: flex;
	align-items: center;
	justify-content: center;

	& + & {
		margin-left: 10px;
	}

	[data-locale='us'] & {
		background-color: transparent;
		width: 60px;

		color: black;
		font-size: 24px;
		font-weight: $gotham-book;
	}

	[data-locale='us'][data-theme='velo'] & {
		color: white;
		font-family: $font-family-velo;
		font-weight: $din-pro-medium;
		margin-left: 0;
	}

	[data-locale='za'] & {
		background-color: transparent;
		min-width: 65px;

		font-size: 24px;
		font-weight: $gotham-book;
		justify-content: flex-start;
	}

	[data-locale='uk'] &::after {
		content: unset;
	}

	[data-locale='pl'][data-theme='velo'] & {
		font-size: 14px;
		width: 32px;
		height: 32px;
	}

	&::after {
		content: '';
		border-radius: inherit;
		background-color: inherit;
		opacity: 0.6;
		transform: translate(-50%, -50%) scale(1.2);

		position: absolute;
		width: 100%;
		height: 100%;

		top: 50%;
		left: 50%;

		z-index: -1;
	}
}

.variantWeight {
	font-size: rescale(9px);
	margin-top: rescale(8px);

	&.bars {
		font-weight: $gotham-medium !important;
		margin-top: 12px !important;
	}

	[data-locale='us'] & {
		font-size: 14px;
		margin-top: 0;

		font-weight: $din-pro-medium;
	}

	[data-locale='us'] & {
		font-size: 14px;
	}

	[data-locale='za'] & {
		margin-top: 5px;
	}
}

.availableText {
	color: var(--text-color, black);
	font-size: 18px;
	line-height: 1.666;
	font-weight: $gotham-book;
}
.nicLevelsContainer {
	display: grid;
	grid-template-columns: repeat(var(--columns, 2), auto);

	[data-locale='za'][data-theme='glo'] & {
		max-width: 540px;
	}
	[data-locale='za'][data-theme='velo'] & {
		max-width: 120px;
	}
}

.dots {
	display: flex;
	flex-flow: row nowrap;

	.horizontal & {
		flex: 0 0 auto;
	}
}
.marginTop {
	margin-top: 25px;
}
.levelWrapper {
	display: flex;

	padding-top: 5px;
	padding-bottom: 5px;

	&.horizontal {
		align-items: center;
		text-transform: uppercase;
		padding-top: 0;
		padding-bottom: 0;

		&:nth-of-type(odd) {
			padding-right: 35px;

			[data-locale='za'][data-theme='glo'] & {
				padding-right: 60px;
			}
		}

		.flavourName {
			flex: 1 1 90px;
		}
	}

	&.vertical {
		flex-direction: column;
	}

	.variantLevelWeight {
		margin-left: 5px;
		min-width: 15px;
		text-align: center;

		[data-locale='za'][data-theme='glo'] & {
			color: var(--flavour-color, white);
		}

		.horizontal & {
			flex: 0 0 auto;
		}
	}
}

.levelWrapperSingle {
	display: flex;

	padding-top: 5px;
	padding-bottom: 5px;

	&.horizontal {
		align-items: center;
		text-transform: uppercase;
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 180px !important;
		.flavourName {
			flex: 1 1 90px;
		}
	}

	&.vertical {
		flex-direction: column;
	}

	.variantLevelWeight {
		margin-left: 5px;
		min-width: 15px;
		text-align: center;

		[data-locale='za'][data-theme='glo'] & {
			color: var(--flavour-color, white);
		}

		.horizontal & {
			flex: 0 0 auto;
		}
	}
}


.levelWrapper:not(:first-of-type):not(.horizontal) {
	padding-left: 40px;
	border-left: 1px solid var(--text-color, black);
}

.dot {
	width: 15.5px;
	height: 15.5px;

	background-color: transparent;
	border: 1px solid var(--theme-color);
	border-radius: 100%;

	margin: 10px 2px;
}
.dotFilled {
	background-color: var(--theme-color);
}
.variantDotsWeight {
	font-size: 20px;
	line-height: 0.9;
	font-weight: $gotham-book;
}

.barsContainer {
	margin-top: 15px;
	display: flex;
	flex-direction: nowrap row;
}
.bars {
	display: flex;
	flex-direction: column;

	margin-right: 16px;
}

.barGroups {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;
	justify-content: center;

	.bar {
		flex: 0 0 auto;
		width: 5px;
		height: 28px;

		border-radius: 4px;
		border: 0.5px solid white;
		margin-right: 2.5px;

		&.full {
			background-color: white;
		}

		[data-theme='epok'] &,
		[data-theme='velo'] &,
		[data-theme='vuse'][data-locale='us'] & {
			border: 0.5px solid black;

			&.full {
				background-color: black;
			}
		}

		&:nth-child(1) {
			height: 8px;
		}
		&:nth-child(2) {
			height: 12px;
		}
		&:nth-child(3) {
			height: 20px;
		}

		&.biggerBars {
			height: 28px;

			&:nth-child(1) {
				height: 12px;
			}
			&:nth-child(2) {
				height: 20px;
			}
			&:nth-child(3) {
				height: 28px;
			}
		}
	}
}

.barValue {
	text-transform: uppercase;
	font-weight: $gotham-medium;

	text-align: center;
	margin-top: 5px;
	font-size: 20px;
	letter-spacing: 0.04em;

	line-height: 21px;

	&.zero {
		font-size: 11px;
		padding-top: 3px;
	}

	&.bigger {
		font-size: 22px;
	}
}
